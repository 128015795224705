import React from 'react';
import Highlight from 'react-highlight';
import { BankImageCard, Button } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

const BankImageCardPage: React.FC = () => {

    return (
        <div>
            <h1 className="mb-s">BankImageCard</h1>

            <div className="d-flex">
                <BankImageCard
                    className="mr-s"
                    image={{
                        src: `${process.env.PUBLIC_URL}/img/ovation-logo.png`,
                        alt: 'Vanguard Bank Logo'
                    }}
                    description="Ovation description."
                    region="Northern"
                    actionButton={<Button dataUI={getDataUI()} btnType="secondary" icon="fal fa-pen">Edit</Button>}
                    isDefault
                    dataUI={getDataUI()}
                />

                <BankImageCard
                    className="mr-s"
                    image={{
                        src: `${process.env.PUBLIC_URL}/img/first-state-com-bank-logo.png`,
                        alt: 'FirstStateCom Bank Logo'
                    }}
                    actionButton={<Button dataUI={getDataUI()} btnType="secondary" icon="fal fa-pen">Edit</Button>}
                    dataUI={getDataUI()}
                />

                <BankImageCard
                    className="mr-s"
                    image={{
                        src: `${process.env.PUBLIC_URL}/img/ion-bank-logo.png`,
                        alt: 'ION Bank Logo'
                    }}
                    description="Image description."
                    region="Northern"
                    actionButton={<Button dataUI={getDataUI()} btnType="secondary" icon="fal fa-pen">Edit</Button>}
                    dataUI={getDataUI()}
                />

                <BankImageCard
                    image={{
                        src: `${process.env.PUBLIC_URL}/img/vanguard-bank-logo.jpg`,
                        alt: 'Vanguard Bank Logo'
                    }}
                    region="Northern"
                    actionButton={<Button dataUI={getDataUI()} btnType="secondary" icon="fal fa-pen">Edit</Button>}
                    dataUI={getDataUI()}
                />
            </div>


            <Highlight className="React">
                {
                    `
import React from 'react';
import { BankImageCard,  Button } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

const Example: React.FC = (props) => {
    return (
        <div className="d-flex">
        <BankImageCard
            className="mr-s"
            image={{
                src: \`${process.env.PUBLIC_URL}/img/ovation-logo.png\`,
                alt: 'Vanguard Bank Logo'
            }}
            description="Ovation description."
            region="Northern"
            actionButton={<Button dataUI={getDataUI()} btnType="secondary" icon="fal fa-pen">Edit</Button>}
            isDefault
            dataUI={getDataUI()}
        />

        <BankImageCard
            className="mr-s"
            image={{
                src: \`${process.env.PUBLIC_URL}/img/first-state-com-bank-logo.png\`,
                alt: 'FirstStateCom Bank Logo'
            }}
            actionButton={<Button dataUI={getDataUI()} btnType="secondary" icon="fal fa-pen">Edit</Button>}
            dataUI={getDataUI()}
        />

        <BankImageCard
            className="mr-s"
            image={{
                src: \`${process.env.PUBLIC_URL}/img/ion-bank-logo.png\`,
                alt: 'ION Bank Logo'
            }}
            description="Image description."
            region="Northern"
            actionButton={<Button dataUI={getDataUI()} btnType="secondary" icon="fal fa-pen">Edit</Button>}
            dataUI={getDataUI()}
        />

        <BankImageCard
            image={{
                src: \`${process.env.PUBLIC_URL}/img/vanguard-bank-logo.jpg\`,
                alt: 'Vanguard Bank Logo'
            }}
            region="Northern"
            actionButton={<Button dataUI={getDataUI()} btnType="secondary" icon="fal fa-pen">Edit</Button>}
            dataUI={getDataUI()}
        />
    </div>
    );
}

export default Example;
    `}
            </Highlight>

            <h2 className="mb-m">API</h2>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>image</td>
                        <td>object with: "img": string, "alt": string</td>
                        <td className="text-center">-</td>
                        <td>Sets the image.</td>
                    </tr>
                    <tr>
                        <td>description</td>
                        <td>string</td>
                        <td>'No Description'</td>
                        <td>Sets the description of the card.</td>
                    </tr>
                    <tr>
                        <td>region</td>
                        <td>string</td>
                        <td>'N/A'</td>
                        <td>Sets the region text of the card.</td>
                    </tr>
                    <tr>
                        <td>actionButton</td>
                        <td>JSX.Element</td>
                        <td className="text-center">-</td>
                        <td>Sets the element for actionButton.</td>
                    </tr>
                    <tr>
                        <td>isDefault</td>
                        <td>boolean</td>
                        <td>false</td>
                        <td>Sets card default badge.</td>
                    </tr>
                </tbody>
            </table>

        </div>
    )
}

export default BankImageCardPage;
