import React from "react";
import {
    ReactAux, MainContainerHeader, ActionContainer,
    Button, ActionContainerHeader, ActionContainerCrumbs, MainContainerScrollable, Toolbar, Input, SimpleTableContainer,
    Tooltip,
    LinkWithIcon,
    BankImageCard,
    Container,
    Icon,
    Col,
    Row,
    Select
} from '@jkhy/vsg-loanvantage-design-system';
import KendoTableMultipleHeaders from './KendoTable/KendoTableMultipleHeaders';
import { getDataUI } from "../helpers/helpers";

const DemoBankHolidays = (props: any) => {

    return (
        <ReactAux>
            <MainContainerHeader dataUI={getDataUI()}>
                <ActionContainer dataUI={getDataUI()}>
                    <ActionContainerHeader dataUI={getDataUI()}>
                        <ActionContainerCrumbs dataUI={getDataUI()} crumbs={[<a href="/">Home</a>, <a href="/">Organization</a>, 'Bank, Bank Holidays and Historical Periods']} />
                    </ActionContainerHeader>
                </ActionContainer>
            </MainContainerHeader>

            <MainContainerScrollable>
                <div className="section-container mt-m mb-m">
                    <h2 className="mb-m">Bank</h2>
                    <hr className="mb-m" />
                    <Toolbar
                        className="mb-m"
                        dataUI={getDataUI()}
                        leftSide={
                            <>
                                <Button dataUI={getDataUI()} className="mr-s" icon="fal fa-pen">Edit</Button>
                            </>
                        }
                        rightSide={
                            <LinkWithIcon dataUI={getDataUI()} icon="fal fa-info-circle" iconPlace="left"><a href="/">About Bank</a></LinkWithIcon>
                        }
                    />

                    <Row>
                        <Col xs="4">
                            <Row>
                                <Col xs="6">
                                    <label className="lv-label justify-content-end text-right">
                                        Bank Name
                                    </label>
                                </Col>
                                <Col xs="6" className="mb-s">
                                    <label className="lv-label">
                                        <strong>Ovation Bank 2</strong>
                                    </label>
                                </Col>
                                <Col xs="6">
                                    <label className="lv-label justify-content-end text-right">
                                        Bank Number
                                    </label>
                                </Col>
                                <Col xs="6" className="mb-s">
                                    <label className="lv-label">
                                        <strong>840</strong>
                                    </label>
                                </Col>
                                <Col xs="6">
                                    <label className="lv-label justify-content-end text-right">
                                        President’s Name
                                    </label>
                                </Col>
                                <Col xs="6" className="mb-s">
                                    <label className="lv-label">
                                        <strong>Todor Zhivkov 11b</strong>
                                    </label>
                                </Col>
                                <Col xs="6">
                                    <label className="lv-label justify-content-end text-right">
                                        Phone
                                    </label>
                                </Col>
                                <Col xs="6" className="mb-s">
                                    <label className="lv-label">
                                        <strong>407-555-5502</strong>
                                    </label>
                                </Col>
                                <Col xs="6">
                                    <label className="lv-label justify-content-end text-right">
                                        Fax
                                    </label>
                                </Col>
                                <Col xs="6" className="mb-s">
                                    <label className="lv-label">
                                        <strong>407-555-5502</strong>
                                    </label>
                                </Col>
                                <Col xs="6">
                                    <label className="lv-label justify-content-end text-right">
                                        Address
                                    </label>
                                </Col>
                                <Col xs="6" className="mb-s">
                                    <label className="lv-label">
                                        <strong>7016 S Cedar St</strong>
                                    </label>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs="4">
                            <Row>
                                <Col xs="6">
                                    <label className="lv-label justify-content-end text-right">
                                        Address 2
                                    </label>
                                </Col>
                                <Col xs="6" className="mb-s">
                                    <label className="lv-label">
                                    </label>
                                </Col>
                                <Col xs="6">
                                    <label className="lv-label justify-content-end text-right">
                                        City
                                    </label>
                                </Col>
                                <Col xs="6" className="mb-s">
                                    <label className="lv-label">
                                        <strong>Lansing</strong>
                                    </label>
                                </Col>
                                <Col xs="6">
                                    <label className="lv-label justify-content-end text-right">
                                        State
                                    </label>
                                </Col>
                                <Col xs="6" className="mb-s">
                                    <label className="lv-label">
                                        <strong>MI</strong>
                                    </label>
                                </Col>
                                <Col xs="6">
                                    <label className="lv-label justify-content-end text-right">
                                        Zip Code
                                    </label>
                                </Col>
                                <Col xs="6" className="mb-s">
                                    <label className="lv-label">
                                        <strong>48911</strong>
                                    </label>
                                </Col>
                                <Col xs="6">
                                    <label className="lv-label justify-content-end text-right">
                                        County
                                    </label>
                                </Col>
                                <Col xs="6" className="mb-s">
                                    <label className="lv-label">
                                        <strong>Lansing</strong>
                                    </label>
                                </Col>
                                <Col xs="6">
                                    <label className="lv-label justify-content-end text-right">
                                        Routing Number
                                    </label>
                                </Col>
                                <Col xs="6" className="mb-s">
                                    <label className="lv-label">
                                        <strong>234567892</strong>
                                    </label>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs="4">
                            <Row>
                                <Col xs="6">
                                    <label className="lv-label justify-content-end text-right">
                                        Legal Entity Identifier
                                    </label>
                                </Col>
                                <Col xs="6" className="mb-s">
                                    <label className="lv-label">
                                        10Bx939c5543Tq
                                    </label>
                                </Col>
                                <Col xs="6">
                                    <label className="lv-label justify-content-end text-right">
                                        Loan Exposure Limit
                                    </label>
                                </Col>
                                <Col xs="6" className="mb-s">
                                    <label className="lv-label">
                                        <strong>$8,000,000,000</strong>
                                    </label>
                                </Col>
                                <Col xs="6">
                                    <label className="lv-label justify-content-end text-right">
                                        Risk Based Capital
                                    </label>
                                </Col>
                                <Col xs="6" className="mb-s">
                                    <label className="lv-label">
                                        <strong>$2,500,000,000</strong>
                                    </label>
                                </Col>
                                <Col xs="6">
                                    <label className="lv-label justify-content-end text-right">
                                        Loan Amount
                                    </label>
                                </Col>
                                <Col xs="6" className="mb-s">
                                    <label className="lv-label">
                                        <strong>$750,000,000</strong>
                                    </label>
                                </Col>
                                <Col xs="6">
                                    <label className="lv-label justify-content-end text-right">
                                        Assets Amount
                                    </label>
                                </Col>
                                <Col xs="6" className="mb-s">
                                    <label className="lv-label">
                                        <strong>$4,500,000,000</strong>
                                    </label>
                                </Col>
                                <Col xs="6">
                                    <label className="lv-label justify-content-end text-right">
                                        NMLSR ID
                                    </label>
                                </Col>
                                <Col xs="6" className="mb-s">
                                    <label className="lv-label">
                                        <strong>123456789</strong>
                                    </label>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>

                <div className="section-container mb-m">
                    <h2 className="mb-m">Bank Holidays</h2>
                    <hr className="mb-m" />
                    <Toolbar
                        className="mb-m"
                        dataUI={getDataUI()}
                        leftSide={
                            <>
                                <Button dataUI={getDataUI()} className="mr-s" icon="fal fa-plus">Add Bank Holiday</Button>
                                <Input dataUI={getDataUI()} icon="fal fa-search" className="width-m" placeholder="Search" />
                            </>
                        }
                        rightSide={
                            <LinkWithIcon dataUI={getDataUI()} icon="fal fa-info-circle" iconPlace="left"><a href="/">About Bank Holidays</a></LinkWithIcon>
                        }
                    />

                    <SimpleTableContainer dataUI={getDataUI()} >
                        <table>
                            <thead>
                                <tr>
                                    <th style={{ width: 42 }}></th>
                                    <th style={{ width: 52 }}>Active</th>
                                    <th>Name</th>
                                    <th>2022</th>
                                    <th>2023</th>
                                    <th>2024</th>
                                    <th>2024</th>
                                    <th>2024</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className='d-flex justify-content-center'>
                                            <Tooltip title="Edit">
                                                <Button
                                                    btnType="icon"
                                                    dataUI={getDataUI()}
                                                    icon='fal fa-pen'
                                                />
                                            </Tooltip>
                                        </div>
                                    </td>
                                    <td className="text-center">
                                        <Icon icon="fas fa-check-circle text-success" size="M" dataUI={getDataUI()} />
                                    </td>
                                    <td>New Year’s Day</td>
                                    <td>01/01/2022</td>
                                    <td>01/01/2023</td>
                                    <td>01/01/2024</td>
                                    <td>01/01/2024</td>
                                    <td>01/01/2024</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='d-flex justify-content-center'>
                                            <Tooltip title="Edit">
                                                <Button
                                                    btnType="icon"
                                                    dataUI={getDataUI()}
                                                    icon='fal fa-pen'
                                                />
                                            </Tooltip>
                                        </div>
                                    </td>
                                    <td className="text-center"></td>
                                    <td>Martin Luther King Jr. Day</td>
                                    <td>17/01/2022</td>
                                    <td>16/01/2023</td>
                                    <td>15/01/2024</td>
                                    <td>15/01/2024</td>
                                    <td>15/01/2024</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='d-flex justify-content-center'>
                                            <Tooltip title="Edit">
                                                <Button
                                                    btnType="icon"
                                                    dataUI={getDataUI()}
                                                    icon='fal fa-pen'
                                                />
                                            </Tooltip>
                                        </div>
                                    </td>
                                    <td className="text-center">
                                        <Icon icon="fas fa-check-circle text-success" size="M" dataUI={getDataUI()} />
                                    </td>
                                    <td>Washington;s Birthday (Presidents Day)</td>
                                    <td>20/02/2022</td>
                                    <td>20/02/2023</td>
                                    <td>20/02/2024</td>
                                    <td>20/02/2024</td>
                                    <td>20/02/2024</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='d-flex justify-content-center'>
                                            <Tooltip title="Edit">
                                                <Button
                                                    btnType="icon"
                                                    dataUI={getDataUI()}
                                                    icon='fal fa-pen'
                                                />
                                            </Tooltip>
                                        </div>
                                    </td>
                                    <td className="text-center">
                                        <Icon icon="fas fa-check-circle text-success" size="M" dataUI={getDataUI()} />
                                    </td>
                                    <td>Memorial Day</td>
                                    <td>30/05/2022</td>
                                    <td>29/05/2023</td>
                                    <td>29/05/2023</td>
                                    <td>29/05/2023</td>
                                    <td>29/05/2023</td>
                                </tr>
                            </tbody>
                        </table>
                    </SimpleTableContainer>
                </div>

                <div className="section-container mb-m">
                    <h2 className="mb-m">Bank Image</h2>
                    <hr className="mb-m" />
                    <Toolbar
                        className="mb-m"
                        dataUI={getDataUI()}
                        leftSide={
                            <Button dataUI={getDataUI()} className="mr-s" icon="fal fa-plus">Add Image</Button>
                        }
                        rightSide={
                            <LinkWithIcon dataUI={getDataUI()} icon="fal fa-info-circle" iconPlace="left"><a href="/">About Bank Images</a></LinkWithIcon>
                        }
                    />

                    <Container className="justify-content-center flex-wrap" gap="S" dataUI={getDataUI()}>
                        <BankImageCard
                            image={{
                                src: `${process.env.PUBLIC_URL}/img/ovation-logo.png`,
                                alt: 'Vanguard Bank Logo'
                            }}
                            description="Ovation description."
                            region="Northern"
                            actionButton={<Button dataUI={getDataUI()} btnType="secondary" icon="fal fa-pen">Edit</Button>}
                            isDefault
                            dataUI={getDataUI()}
                        />

                        <BankImageCard
                            image={{
                                src: `${process.env.PUBLIC_URL}/img/first-state-com-bank-logo.png`,
                                alt: 'FirstStateCom Bank Logo'
                            }}
                            actionButton={<Button dataUI={getDataUI()} btnType="secondary" icon="fal fa-pen">Edit</Button>}
                            dataUI={getDataUI()}
                        />

                        <BankImageCard
                            image={{
                                src: `${process.env.PUBLIC_URL}/img/ion-bank-logo.png`,
                                alt: 'ION Bank Logo'
                            }}
                            description="Image description."
                            region="Northern"
                            actionButton={<Button dataUI={getDataUI()} btnType="secondary" icon="fal fa-pen">Edit</Button>}
                            dataUI={getDataUI()}
                        />

                        <BankImageCard
                            image={{
                                src: `${process.env.PUBLIC_URL}/img/vanguard-bank-logo.jpg`,
                                alt: 'Vanguard Bank Logo'
                            }}
                            region="Northern"
                            actionButton={<Button dataUI={getDataUI()} btnType="secondary" icon="fal fa-pen">Edit</Button>}
                            dataUI={getDataUI()}
                        />
                    </Container>
                </div>

                <div className="section-container mb-m">
                    <h2 className="mb-m">Historical Periods</h2>
                    <hr className="mb-m" />
                    <Toolbar
                        className="mb-m"
                        dataUI={getDataUI()}
                        rightSide={
                            <LinkWithIcon dataUI={getDataUI()} icon="fal fa-info-circle" iconPlace="left"><a href="/">About Historical Periods</a></LinkWithIcon>
                        }
                    />

                    <SimpleTableContainer dataUI={getDataUI()} >
                        <table>
                            <thead>
                                <tr>
                                    <th style={{ width: 42 }}></th>
                                    <th>Period Date</th>
                                    <th>Loan Exposure Limit</th>
                                    <th>Risk Based Capital</th>
                                    <th>Loan Amount</th>
                                    <th>Assets Amount</th>
                                    <th>Processed</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className='d-flex justify-content-center'>
                                            <Tooltip title="Edit">
                                                <Button
                                                    btnType="icon"
                                                    dataUI={getDataUI()}
                                                    icon='fal fa-pen'
                                                />
                                            </Tooltip>
                                        </div>
                                    </td>
                                    <td>01/01/2022</td>
                                    <td>$5,000,000,000</td>
                                    <td>$800,000,000</td>
                                    <td>$800,000,000</td>
                                    <td>$800,000,000</td>
                                    <td>01/01/2024</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='d-flex justify-content-center'>
                                            <Tooltip title="Edit">
                                                <Button
                                                    btnType="icon"
                                                    dataUI={getDataUI()}
                                                    icon='fal fa-pen'
                                                />
                                            </Tooltip>
                                        </div>
                                    </td>
                                    <td>31/12/2021</td>
                                    <td>$685,400,000</td>
                                    <td>$685,400,000</td>
                                    <td>$685,400,000</td>
                                    <td>$685,400,000</td>
                                    <td>29/05/2023</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='d-flex justify-content-center'>
                                            <Tooltip title="Edit">
                                                <Button
                                                    btnType="icon"
                                                    dataUI={getDataUI()}
                                                    icon='fal fa-pen'
                                                />
                                            </Tooltip>
                                        </div>
                                    </td>
                                    <td>30/1/2021</td>
                                    <td>$800,000,000</td>
                                    <td>$800,000,000</td>
                                    <td>$800,000,000</td>
                                    <td>$800,000,000</td>
                                    <td>19/02/2024</td>
                                </tr>
                            </tbody>
                        </table>
                    </SimpleTableContainer>
                </div>
            </MainContainerScrollable>
        </ReactAux>
    )
}

export default DemoBankHolidays